import React, { useEffect, useState } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { collection, getDoc, getDocs } from "firebase/firestore";
import { db } from "../../services/firebaseConfig";
import "./CalendarView.css";
import Modal from "react-modal";
import {
  Class,
  DanceFloor,
  DanceStyle,
  Instructor,
  Schedule,
} from "../../models";

Modal.setAppElement("#root");

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

interface Event {
  id: string;
  title: string;
  className: string;
  start: Date;
  end: Date;
  danceFloor: string;
}

const CalendarView: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [, setClasses] = useState<Class[]>([]);
  const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("All");

  const fetchClasses = async () => {
    try {
      const classesCollection = collection(db, "classes");
      const classesSnapshot = await getDocs(classesCollection);
      const classesList = classesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Class[];

      const updatedClasses = await Promise.all(
        classesList.map(async (classItem) => {
          const danceStyleRef = classItem.dance_style_id;
          let danceStyleName = "Unknown Dance Style";
          if (danceStyleRef) {
            const danceStyleDoc = await getDoc(danceStyleRef);
            if (danceStyleDoc.exists()) {
              danceStyleName = (danceStyleDoc.data() as DanceStyle).name;
            }
          }

          const instructorRef = classItem.instructor_id;

          let instructorName = "Unknown Instructor";
          if (instructorRef) {
            const instructorDoc = await getDoc(instructorRef);
            if (instructorDoc.exists()) {
              const instructorData = instructorDoc.data() as Instructor;
              instructorName = `${instructorData.name.first} ${instructorData.name.last}`;
            }
          }

          // Fetch schedules for this class
          const schedulesCollection = collection(
            db,
            `classes/${classItem.id}/schedules`
          );
          const schedulesSnapshot = await getDocs(schedulesCollection);
          const schedulesList = schedulesSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          })) as Schedule[];

          const updatedSchedules = await Promise.all(
            schedulesList.map(async (scheduleItem) => {
              const danceFloorRef = scheduleItem.dance_floor_id;
              let danceFloorName = "Unknown Dance Floor";
              if (danceFloorRef) {
                const danceFloorDoc = await getDoc(danceFloorRef);
                if (danceFloorDoc.exists()) {
                  const danceFloorData = danceFloorDoc.data() as DanceFloor;
                  danceFloorName = danceFloorData.name;
                }
              }

              return {
                ...scheduleItem,
                danceFloor: danceFloorName,
              };
            })
          );

          return {
            ...classItem,
            name: danceStyleName,
            instructor: instructorName,
            schedules: updatedSchedules,
          };
        })
      );

      setClasses(updatedClasses);
      parseClassesToEvents(updatedClasses);
    } catch (error) {
      console.error("Error fetching classes:", error);
    }
  };

  const parseClassesToEvents = (classes: Class[]) => {
    const eventsList: Event[] = [];
    classes.forEach((classItem) => {
      classItem.schedules?.forEach((schedule) => {
        const event: Event = {
          id: schedule.id,
          title: `${classItem.name} - ${classItem.instructor}`,
          className: classItem.name,
          start: new Date(schedule.start.seconds * 1000),
          end: new Date(schedule.end.seconds * 1000),
          danceFloor: schedule.danceFloor || "Unknown",
        };
        eventsList.push(event);
      });
    });
    setEvents(eventsList);
  };

  useEffect(() => {
    fetchClasses();
  });

  const eventStyleGetter = (event: Event) => {
    let backgroundColor = "";
    switch (event.className) {
      case "Zumba":
        backgroundColor = "#e3c001";
        break;
      case "Heels":
        backgroundColor = "#FF8C00";
        break;
      case "Urbano":
        backgroundColor = "#8B0000";
        break;
      case "Reggaeton":
        backgroundColor = "#255D8A";
        break;
      case "Jazz":
        backgroundColor = "#1E90FF";
        break;
      case "Contemporáneo":
        backgroundColor = "#9400D3";
        break;
      case "Salsa":
        backgroundColor = "#FF1493";
        break;
      case "Cumbia":
        backgroundColor = "#6312EC";
        break;
      case "Hip-Hop":
        backgroundColor = "#32CD32";
        break;
      case "Bachata":
        backgroundColor = "#D08932";
        break;
      case "Ballet":
        backgroundColor = "#c06192";
        break;
      case "Salsa Cubana":
        backgroundColor = "#ff7b1d";
        break;
      case "Baile de Salón":
        backgroundColor = "#084c76";
        break;
      case "Urban Kids":
        backgroundColor = "#506b3e";
        break;
      case "Twerk":
        backgroundColor = "#004095";
        break;
      case "Belly Dance":
        backgroundColor = "#650f7d";
        break;
      default:
        backgroundColor = "#e2e8f0";
        break;
    }

    const style = {
      backgroundColor,
      borderRadius: "5px",
      opacity: 0.9,
      color: "white",
      border: "1px solid #fff",
      display: "block",
      padding: "5px",
      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.3)",
    };

    return {
      style,
    };
  };

  const handleEventClick = (event: Event) => {
    setSelectedEvent(event);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEvent(null);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "300px", // Adjust the width of the modal
      padding: "20px",
      borderRadius: "10px",
      border: "2px solid transparent",
      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      backgroundColor: "transparent",
      color: "white",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFilter(e.target.value);
  };

  const filteredEvents =
    filter === "All"
      ? events
      : events.filter((event) => event.className === filter);

  return (
    <div className="wrapper">
      <section className="calendar section-p-top bg-black" id="calendar">
        <Navbar />
        <div className="container">
          <h1 className="section-t text-title text-center">Calendario</h1>
          <div className="filter-container">
            <label className="text-white" htmlFor="filter">
              Filter by Class:
            </label>
            <select
              id="class-filter"
              className="class-filter-dropdown"
              value={filter}
              onChange={handleFilterChange}
              style={{
                marginLeft: "10px",
                padding: "5px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            >
              <option value="All">All</option>
              <option value="Zumba">Zumba</option>
              <option value="Heels">Heels</option>
              <option value="Urbano">Urbano</option>
              <option value="Reggaeton">Reggaeton</option>
              <option value="Jazz">Jazz</option>
              <option value="Contemporáneo">Contemporáneo</option>
              <option value="Salsa">Salsa</option>
              <option value="Cumbia">Cumbia</option>
              <option value="Hip-Hop">Hip-Hop</option>
              <option value="Bachata">Bachata</option>
            </select>
          </div>
          <Calendar
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            views={[Views.WEEK, Views.DAY]}
            step={30}
            defaultView={Views.WEEK}
            style={{
              height: "100%",
              margin: "5px",
              backgroundColor: "white",
              color: "black",
            }}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={handleEventClick}
            min={new Date(2024, 1, 1, 7, 0)} // Start at 7 AM
          />
          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Event Details"
            style={customStyles}
          >
            <div className="modal-wrapper">
              <button
                onClick={closeModal}
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "transparent",
                  border: "none",
                  fontSize: "1.0rem",
                  cursor: "pointer",
                }}
              >
                &times;
              </button>
              {selectedEvent && (
                <div>
                  <h2 className="modal-title">{selectedEvent.title}</h2>
                  <p>
                    <strong className="modal-subtitle">Start:</strong>{" "}
                    {moment(selectedEvent.start).format(
                      "ddd MMM DD YYYY - hh:mm A"
                    )}
                  </p>
                  <p>
                    <strong className="modal-subtitle">End:</strong>{" "}
                    {moment(selectedEvent.end).format(
                      "ddd MMM DD YYYY - hh:mm A"
                    )}
                  </p>
                  <p>
                    <strong className="modal-subtitle">Dance Floor:</strong>{" "}
                    {selectedEvent.danceFloor}
                  </p>
                </div>
              )}
            </div>
          </Modal>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CalendarView;
